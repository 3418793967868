import React, { Component } from 'react';
import Page from '../../containers/page';
import FormBuilder from '../../form-builder';
import RouteComponent from '../../helpers/route';
import ListBuilder from '../../list-builder';
import Isvg from 'react-inlinesvg';
import {
    Container,
    Row,
    Col,
    Table,
    FormGroup, Label, Input,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button
} from 'reactstrap';
import editIcon from '../../assets/edit.svg';
import garbageIcon from '../../assets/garbage.svg';
import reportIcon from '../../assets/report_icon.svg';

import printIcon from '../../assets/print.svg';
import saveIcon from '../../assets/save.svg';
import DeleteModal from '../../components/deleteModal';
import { required, number, minValue, maxValue, minLength, maxLength, length, email } from '../../form-builder/validation';

import Toogle from '../../form-builder/fields/switch';

import moment from 'moment';
class Screen extends RouteComponent {
    constructor(props) {
        super(props);
        this.state = {
            articlesList: true,
            articleForm: false,
            total: 20,
            modules: [],
            items: [

            ]
        }
    }

    componentDidMount() {
        this._onLoad();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props[0].location.search != prevProps[0].location.search) {
            this._onLoad();
        }
    }

    render() {
        let params = this._getParams();

        return (
            <div>
                <div className="section-title title-options">
                    <h1>Comments</h1>
                </div>

                <Container fluid style={{ paddingRight: 0 }}>
                    <DeleteModal
                        isOpen={this.state.deleteModal}
                        toggle={() => this.setState({ deleteModal: null })}
                        handler={() => {
                            this._api('api3', { data: this.state.deleteModal._id }, () => {
                                this.setState({ groupsForm: {} }, () => { this._onLoad(); this.setState({ deleteModal: null }) })
                            });
                        }} >
                        Delete comment <strong>{this.state.deleteModal ? this.state.deleteModal.name : ''}</strong> ?
                    </DeleteModal>
                    <Row>

                        <Col lg="12">
                            <div className="table-modifed ml-15 second-td-witdth-unset">
                                <ListBuilder
                                    tabs={false}
                                    sort={false}
                                    header={'List'}
                                    lang={this.props.lang}
                                    loading={this.state.loading}
                                    total={this.state.total}
                                    showNumeration={false}
                                    customOptions={['Reported']}
                                    fields={[
                                        { type: 'text', name: 'comment', label: 'COMMENT'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'clientName', label: 'AUTHOR'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'tsCreated', label: 'TIME'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'itemName', label: 'TITLE'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'type', label: 'TYPE'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'numberOfLikes', label: 'LIKES'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'active', label: 'ACTIVE'.translate(this.props.lang), multilang: false, allowSort: true },


                                    ]}
                                    items={this.state.items.map((item, idx) => {
                                        return {
                                            ...item,
                                            tsCreated: item.tsCreated ? moment.unix(item.tsCreated).format('DD-MMM-YYYY HH:mm') : '',
                                            active: <div><Toogle value={item.active} onChange={(value) => {
                                                this._api('api4', { data: { active: value, _id: item._id } }, () => {
                                                    this._onLoad()
                                                });
                                            }} /></div>,
                                            numberOfLikes: item?.numberOfLikes ?? item?.likes.length ?? 0

                                        }
                                    })}
                                    actions={
                                        [
                                            {
                                                component: <Isvg src={reportIcon} className="report-icon" />,
                                                onClick: (item) => {
                                                    this.setState({ reportedItem: item })
                                                },
                                                condition: (item) => {
                                                    if (item?.reports?.length) {
                                                        return true;
                                                    } else {
                                                        return false;
                                                    }
                                                }
                                            },
                                            {
                                                component: <Isvg src={garbageIcon} className="delete-icon" />,
                                                onClick: (item) => this.setState({ deleteModal: item })
                                            },
                                        ]
                                    }
                                    params={params}
                                    sortField={params.sortField}
                                    sortType={params.sortType}
                                    updateSort={this.updateSort}
                                    updateParams={this.updateParams}
                                    updateMultipleParams={this.updateMultipleParams}
                                ></ListBuilder>
                            </div>
                        </Col>
                    </Row>
                </Container>

                {
                    this.state.reportedItem ?
                        <Modal isOpen={this.state.reportedItem} centered>
                            <ModalHeader>
                                Comment
                            </ModalHeader>
                            <ModalBody className='reported-item-body-wrap'>
                                <div>
                                    <h6>Author: <span>{this.state.reportedItem?.clientName}</span></h6>
                                    <h6>Comment: <span>{this.state.reportedItem?.comment}</span></h6>
                                    <h6>Time: <span>{this.state.reportedItem?.tsCreated}</span></h6>
                                    <h6>Title: <span>{this.state.reportedItem?.itemName} ({this.state.reportedItem?.type})</span></h6>
                                </div>

                                <div className='reports-wrap'>
                                    <h6>REPORTS:</h6>
                                    {
                                        this.state.reportedItem?.reports.map((item, idx) => {
                                            return(
                                                <div className='report-item'>
                                                    <h6>{item.clientName} <span>{item.timestamp ? moment.unix(item.timestamp).format('DD-MMM-YYYY HH:mm') : ''}</span></h6>
                                                    <p>{item.reason}</p>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </ModalBody>
                            <ModalFooter>
                                <Button type="button" color="danger" onClick={() => this.setState({
                                    reportedItem: false
                                })}>{'Close'.translate(this.props.lang)}</Button>
                            </ModalFooter>
                        </Modal>
                        :
                        null
                }

            </div>
        )
    }
}

export default Page(Screen);
