import React, { Component } from 'react';
import Page from '../../containers/page';
import FormBuilder from '../../form-builder';
import RouteComponent from '../../helpers/route';
import ListBuilder from '../../list-builder';
import Isvg from 'react-inlinesvg';
import {
    Container,
    Row,
    Col,
    Table,
    FormGroup, Label, Input
} from 'reactstrap';
import editIcon from '../../assets/edit.svg';
import garbageIcon from '../../assets/garbage.svg';
import printIcon from '../../assets/print.svg';
import saveIcon from '../../assets/save.svg';
import DeleteModal from '../../components/deleteModal';
import pushIcon from '../../assets/push.svg';

import { required, number, minValue, maxValue, minLength, maxLength, length, email } from '../../form-builder/validation';
class Screen extends RouteComponent {
    constructor(props) {
        super(props);
        this.state = {
            articlesList: true,
            articleForm: false,
            total: 20,
            modules: [],
            leagues: [],
            teams: [],
            items: [

            ]
        }
    }

    componentDidMount() {
        this._onLoad();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props[0].location.search != prevProps[0].location.search) {
            this._onLoad();
        }
    }

    render() {
        let params = this._getParams();
        console.log(this.state)

        return (
            <div>
                <div className="section-title title-options">
                    <h1>Video & Pictures uploads</h1>
                </div>

                <Container fluid style={{ paddingRight: 0 }}>
                    <DeleteModal
                        isOpen={this.state.deleteModal}
                        toggle={() => this.setState({ deleteModal: null })}
                        handler={() => {
                            this._api('api3', { data: this.state.deleteModal._id }, () => {
                                this.setState({ groupsForm: {} }, () => { this._onLoad(); this.setState({ deleteModal: null }) })
                            });
                        }} >
                        Delete video <strong>{this.state.deleteModal ? this.state.deleteModal.name : ''}</strong> ?
                    </DeleteModal>
                    <DeleteModal
                        isOpen={this.state.appPush}
                        toggle={() => this.setState({ appPush: null })}
                        handler={() => {
                            this._api('push', { data: this.state.appPush._id }, () => {
                                this.setState({ groupsForm: {} }, () => { this._onLoad(); this.setState({ appPush: null }) })
                            });
                        }} >
                        Send notification for <strong>{this.state.appPush ? this.state.appPush.title : ''}</strong> ?
                    </DeleteModal>

                    <Row>
                        <Col lg="6">
                            <FormBuilder
                                registerSubmitAction={(submit) => this.submit = submit}
                                classNameForm={"add-form-container"}
                                // headerForm={"Artikal".translate(this.props.lang)}
                                addButtonText={'Add'.translate(this.props.lang)}
                                onSubmit={(dat) => {

                                    let data = { ...dat };



                                    if (this.state.form && this.state.form._id) {

                                        this._api('api4', { data: data }, () => {
                                            this.setState({ form: null }, () => { this._onLoad(); })
                                        });
                                    } else {

                                        this._api('api2', { data: data }, () => {
                                            this.setState({ form: null }, () => { this._onLoad() })
                                        });
                                    }
                                }}
                                initialValues={this.state.form ? this.state.form : null}
                                fields={[
                                    {
                                        type: 'row',
                                        children: [
                                            {
                                                type: 'col',
                                                width: { lg: 12, sm: 12, xs: 12 },
                                                children: [
                                                    {
                                                        type: 'block',
                                                        header: 'Video'.translate(this.props.lang),
                                                        children: [
                                                            {
                                                                type: 'col',
                                                                width: { lg: 12, sm: 12, xs: 12 },
                                                                children: [
                                                                    {
                                                                        type: 'text',
                                                                        name: 'title',
                                                                        label: 'Title',
                                                                        asyncValidation: true,
                                                                        validate: [
                                                                            required('Required!'),
                                                                        ]
                                                                    },

                                                                ]
                                                            },
                                                            {
                                                                type: 'col',
                                                                width: { lg: 12, sm: 12, xs: 12 },
                                                                children: [
                                                                    {
                                                                        type: 'textarea',
                                                                        name: 'description',
                                                                        label: 'Description',
                                                                        asyncValidation: true,
                                                                        validate: [
                                                                            required('Required!'),
                                                                        ]
                                                                    },

                                                                ]
                                                            },
                                                            {
                                                                type: 'col',
                                                                width: { lg: 12, sm: 12, xs: 12 },
                                                                children: [
                                                                    {
                                                                        type: 'video',
                                                                        name: 'video',
                                                                        label: 'Video',
                                                                        asyncValidation: true,
                                                                        validate: [
                                                                        ]
                                                                    },

                                                                ]
                                                            },

                                                            {
                                                                type: 'col',
                                                                width: { lg: 12, sm: 12, xs: 12 },
                                                                children: [
                                                                    {
                                                                        type: 'switch',
                                                                        name: 'active',
                                                                        next: 'level',
                                                                        label: 'Active',
                                                                    },

                                                                ]
                                                            },


                                                        ]
                                                    },



                                                ],

                                            },
                                            this.state.form ?
                                            {
                                                type: 'button',
                                                width: { lg: 12, sm: 6, xs: 6 },
                                                action: 'submit',
                                                children: 'Save',
                                                className: 'form-button',
                                            } : null
                                        ]
                                    },


                                ]}
                            ></FormBuilder>
                        </Col>
                        <Col lg="6">
                            <div className="table-modifed ml-15">
                                <ListBuilder
                                    tabs={false}
                                    sort={false}
                                    header={'List'}
                                    lang={this.props.lang}
                                    loading={this.state.loading}
                                    total={this.state.total}
                                    showNumeration={false}
                                    fields={[
                                        { type: 'text', name: 'title', label: 'TITLE'.translate(this.props.lang), multilang: false, allowSort: true },

                                    ]}
                                    items={this.state.items}
                                    actions={
                                        [
                                            {
                                                component: <Isvg src={editIcon} className="delete-icon" />,
                                                onClick: (item) => {




                                                    this.setState({ form: null }, () => this.setState({ form: item }))

                                                }
                                                //onClick: (item) => console.log(item)
                                            },
                                            {
                                                component: <Isvg src={pushIcon} className="delete-icon" />,
                                                onClick: (item) => this.setState({ appPush: item })
                                            },

                                            {
                                                component: <Isvg src={garbageIcon} className="delete-icon" />,
                                                onClick: (item) => this.setState({ deleteModal: item })
                                            },
                                        ]
                                    }
                                    params={params}
                                    sortField={params.sortField}
                                    sortType={params.sortType}
                                    updateSort={this.updateSort}
                                    updateParams={this.updateParams}
                                    updateMultipleParams={this.updateMultipleParams}
                                ></ListBuilder>
                            </div>
                        </Col>
                    </Row>
                </Container>

            </div>
        )
    }
}

export default Page(Screen);
